<template>
  <div class="flex justify-between align-items-end flex-wrap w-100 mb-4">
    <div class="flex flex-wrap">
      <div class="mr-4 text-sm">
        <EventStatusSelect
          id="status"
          ref="status"
          @input="onStatusFilter"
          :defaultSelectValue="filters.status"
          :showLabel="true"
          size="compact"
        >
          <template slot="label" class="mb-1">
            <p class="mb-1">Status</p>
          </template>
        </EventStatusSelect>
      </div>
      <div class="controls__date-range">
        <DateRangeSelect
          id="date-range"
          ref="date-range"
          :defaultStartDate="filters.fromDate"
          :defaultEndDate="filters.toDate"
          @startDate="onStartDate"
          @endDate="onEndDate"
          class="control__date-range"
          :showLabel="true"
          size="compact"
        />
      </div>
    </div>
  </div>
</template>

<script>
import EventStatusSelect from '@/components/inputs/EventStatusSelect';
import DateRangeSelect from '@/components/inputs/DateRangeSelect';

export default {
  provide() {
    return { parentValidator: this.$validator };
  },
  props: {
    filters: {
      type: Object,
      required: true
    }
  },
  components: { EventStatusSelect, DateRangeSelect },
  computed: {
    reportFilters() {
      return {
        status: this.filters.status,
        fromDate: this.filters.fromDate,
        toDate: this.filters.toDate
      };
    }
  },
  methods: {
    onStatusFilter(status) {
      this.reportFilters.status = status;
      this.$emit('filtersChanged', this.reportFilters);
    },
    onStartDate(startDate) {
      this.reportFilters.fromDate = startDate;
      this.$emit('filtersChanged', this.reportFilters);
    },
    onEndDate(endDate) {
      this.reportFilters.toDate = endDate;
      this.$emit('filtersChanged', this.reportFilters);
    }
  }
};
</script>

<style>
.controls__organization .multiselect {
  font-size: 0.875rem;
}

.controls__organization .multiselect__tags {
  border-color: #ced4da;
}

.controls__organization .multiselect__placeholder {
  color: #495057;
}
</style>
