<template>
  <div class="controls">
    <div class="controls__panel">
      <div v-if="admin">
        <label class="controls__label">Organization</label>
        <OrganizationSelect
          id="organization"
          ref="organization"
          @input="onOrganizationFilter"
          class="controls__organization"
        />
        <RbProvinceDropdown v-model="selectedProvince" :required="false" class="controls__label-province" />
      </div>
      <div class="controls__status">
        <EventStatusSelect
          id="status"
          ref="status"
          @input="onStatusFilter"
          :defaultSelectValue="reportFilters.status"
          :showLabel="true"
          size="compact"
        />
      </div>
      <div class="controls__date-range">
        <DateRangeSelect
          id="date-range"
          ref="date-range"
          :defaultStartDate="reportFilters.startDate"
          :defaultEndDate="reportFilters.endDate"
          @startDate="onStartDate"
          @endDate="onEndDate"
          class="control__date-range"
          :showLabel="true"
          size="compact"
        />
      </div>
    </div>
    <div class="controls__buttons">
      <download-excel
        class="btn btn-outline-secondary btn-sm"
        :fields="csvFields"
        :fetch="fetchDataCallback"
        name="report.csv"
        :escapeCsv="false"
        type="csv"
      >
        <b-icon icon="download" aria-label="download"></b-icon> Download CSV
      </download-excel>
    </div>
  </div>
</template>

<script>
import OrganizationSelect from '@/components/OrganizationSelectV2';
import EventStatusSelect from '@/components/inputs/EventStatusSelect';
import DateRangeSelect from '@/components/inputs/DateRangeSelect';
import DownloadExcel from 'vue-json-excel';

export default {
  provide() {
    return { parentValidator: this.$validator };
  },
  props: {
    filters: {
      type: Object,
      required: true
    },
    admin: {
      type: Boolean,
      required: false,
      default: false
    },
    csvFields: {
      type: Object,
      required: true
    },
    fetchData: {
      type: Function,
      required: false
    }
  },
  data() {
    return {
      selectedProvince: '',
      reportFilters: {
        organization: null,
        province: this.filters.province,
        status: this.filters.status,
        startDate: this.filters.startDate,
        endDate: this.filters.endDate
      }
    };
  },
  components: { DownloadExcel, OrganizationSelect, EventStatusSelect, DateRangeSelect },
  watch: {
    selectedProvince: {
      handler() {
        this.onProvinceFilter(this.selectedProvince);
      }
    },
    reportFilters: {
      handler() {
        this.onFiltersChange();
      },
      deep: true
    }
  },
  methods: {
    async fetchDataCallback() {
      return await this.fetchData();
    },
    onProvinceFilter(province) {
      this.reportFilters.province = province;
    },
    onOrganizationFilter(org) {
      this.reportFilters.organization = org;
    },
    onStatusFilter(status) {
      this.reportFilters.status = status;
    },
    onStartDate(startDate) {
      this.reportFilters.startDate = startDate;
    },
    onEndDate(endDate) {
      this.reportFilters.endDate = endDate;
    },
    onFiltersChange() {
      this.$emit('filtersChanged', this.reportFilters);
    }
  }
};
</script>

<style>
.controls {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 1rem;
}

.controls__panel {
  display: flex;
  flex-wrap: wrap;
}

.controls__label {
  display: block;
  font-size: 12px;
  margin-bottom: 0.2rem;
}

.controls__label-province {
  display: block;
  font-size: 0.75rem;
  margin-bottom: 0.2rem;
  margin-top: 0.3rem;
}

.controls__organization,
.controls__province {
  width: 21rem;
  margin-right: 1rem;
  margin-bottom: 0.5rem;
}

.controls__organization .multiselect {
  font-size: 0.875rem;
}

.controls__organization .multiselect__tags {
  border-color: #ced4da;
}

.controls__organization .multiselect__placeholder {
  color: #495057;
}

.controls__status {
  margin-right: 1rem;
}

.controls__buttons {
  display: flex;
  align-items: right;
  margin-bottom: 0.5rem;
}
</style>
