<template>
  <div class="event-status" :class="{ compact: displaySize }">
    <b-form-group label-for="input-event-status">
      <slot v-if="showLabel" name="label">Event Status</slot>
      <b-form-select
        name="input-event-status"
        v-model="selectedValue"
        aria-describedby="input-event-status-feedback"
        :options="options"
        data-vv-as="eventStatus"
      >
        <template #first>
          <b-form-select-option :value="null"> All </b-form-select-option>
        </template>
      </b-form-select>
    </b-form-group>
  </div>
</template>

<script>
export default {
  name: 'EventStatusSelect',
  props: ['showLabel', 'defaultSelectValue', 'size'],
  data() {
    return {
      options: [
        { value: 'draft', text: 'Draft' },
        { value: 'active', text: 'Active' },
        { value: 'ended', text: 'Ended' },
        { value: 'closed', text: 'Closed' }
      ]
    };
  },
  computed: {
    displaySize() {
      return this.size && this.size === 'compact' ? true : false;
    },
    selectedValue: {
      get() {
        return this.defaultSelectValue;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  }
};
</script>

<style>
.event-status .form-group {
  margin-bottom: 0;
}

.event-status .custom-select {
  box-shadow: none;
}

.event-status.compact label {
  font-size: 0.875rem;
  margin-bottom: 0.2rem;
}

.event-status.compact .custom-select {
  font-size: 0.875rem;
  min-height: 43px;
  border-radius: 5px;
}
</style>
