<template>
  <div class="sm:mx-2 md:max-w-full">
    <div class="flex mb-3 justify-between items-center">
      <div class="flex">
        <ReportFiltersV2 :filters="filters" @filtersChanged="filterReport" />
        <LoadingButton variant="outline" class="min-w-max mt-2 self-center" @click.native="clearFilters">
          Clear Filters
        </LoadingButton>
      </div>
      <DownloadReportButton
        @onClick="downloadReport"
        :downloadingReport="downloading"
        v-if="showDownloadReportButton"
      />
    </div>
    <Alert v-if="!loading && error" id="error-alert" class="mt-2" icon="exclamation" variant="red">
      {{ error }}
    </Alert>
    <div>
      <RaffleSalesTable />

      <div class="flex mt-4" id="total-records">
        <p>
          Total Records: <strong>{{ totalRecords }}</strong>
        </p>
      </div>

      <Pagination :pages="pages" :currentPage="currentPage" @updateCurrentPage="updateCurrentPage" class="mt-6" />
    </div>
  </div>
</template>

<script>
import Alert from '@/components/ui/Alert';
import Pagination from '@/components/ui/Pagination';
import LoadingButton from '@/components/ui/LoadingButton';
import ReportFiltersV2 from '@/components/reports/ReportFiltersV2';
import RaffleSalesTable from '@/components/reports/RaffleSalesTable';
import DownloadReportButton from '@/components/ui/DownloadReportButton';

export default {
  components: {
    Alert,
    Pagination,
    LoadingButton,
    ReportFiltersV2,
    RaffleSalesTable,
    DownloadReportButton
  },
  computed: {
    salesReport() {
      return this.$store.state.raffleSalesReport.report;
    },
    filters() {
      return this.$store.state.raffleSalesReport.filters;
    },
    pages() {
      return this.$store.getters.getTotalPages;
    },
    currentPage() {
      return this.$store.state.raffleSalesReport.page;
    },
    totalRecords() {
      return this.$store.state.raffleSalesReport.totalRecords;
    },
    loading() {
      return this.$store.state.raffleSalesReport.loading;
    },
    showDownloadReportButton() {
      return this.salesReport && this.salesReport.length;
    },
    downloading() {
      return this.$store.state.raffleSalesReport.downloading;
    },
    error() {
      return this.$store.state.raffleSalesReport.error;
    }
  },
  methods: {
    async downloadReport() {
      await this.$store.dispatch('downloadRaffleSalesReport');
    },
    async filterReport(filters) {
      this.$store.commit('SET_FILTERS', filters);
      this.updateCurrentPage(0);

      await this.$store.dispatch('getRaffleSalesReport');
    },
    async updateCurrentPage(page) {
      this.$store.commit('SET_CURRENT_PAGE', page);
      await this.$store.dispatch('getRaffleSalesReport');
    },
    async clearFilters() {
      this.$store.commit('CLEAR_FILTERS');
      this.updateCurrentPage(0);

      await this.$store.dispatch('getRaffleSalesReport');
    }
  }
};
</script>
